import { Box, Flex, Select } from "@chakra-ui/react";
import { getDividend } from "api";
import { useEffect, useState } from "react";
import DividendTable from "./components/DividendTable";

const Dividends = () => {
  const [dividends, setDividends] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // create list of years from 2018 to current year
  const years = Array.from({ length: new Date().getFullYear() - 2018 + 1 }, (_, i) => 2018 + i);


  useEffect(() => {
    getDividend(selectedYear).then(setDividends).then(() => setIsLoading(false))
  }, [selectedYear])

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  }

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }} width="100%">
      <Box p="10px" width="100%" style={{ boxSizing: 'border-box' }}>
        <Select placeholder="Select year" onChange={handleYearChange} defaultValue={selectedYear} >
          {years.reverse().map((year, index) => (
            <option key={index} value={year}>{year}</option>
          ))}
        </Select>
      </Box>
      <Box p="10px" width="100%" style={{ boxSizing: 'border-box' }}>
        <DividendTable
          title={"Dividends"}
          dividends={dividends}
          isLoading={isLoading}
          width="100%"
        />
      </Box>
    </Flex>
  );
}

export default Dividends;