import { reloadDividend } from "api";
import { ReloadIcon } from "components/Icons/Icons";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { monthNames } from "utils";
import SummaryTableRow from "./SummaryTableRow";

const { Thead, Tr, Tbody, useColorModeValue, Table, Th, Text, Box, Select, Flex } = require("@chakra-ui/react");
const { default: Card } = require("components/Card/Card");
const { default: CardBody } = require("components/Card/CardBody");
const { default: CardHeader } = require("components/Card/CardHeader");

const DividendSummaryTable = ({ title, data, isLoading }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [selectedYear, setSelectedYear] = useState("All");
  const [captions, setCaptions] = useState(["Year", "Dividends"]);

  const years = Array.from({ length: new Date().getFullYear() - 2018 + 1 }, (_, i) => 2018 + i);
  years.push("All");
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setCaptions(event.target.value !== "All" ? ["Month", "Dividends"] : ["Year", "Dividends"]);
  }

  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  const reloadButton = () => {
    const resolveOnReloadComplete = new Promise((resolve, reject) => reloadDividend().then(resp => {
      if (resp.success) {
        resolve()
      } else {
        reject(new Error('Error reloading dividends'));
      }
    }));
    toast.promise(resolveOnReloadComplete, {
      pending: 'Reloading Dividends...',
      success: 'Dividends Reloaded',
      error: 'Error Reloading Dividends'
    }, {
      position: "top-center"
    })
  }

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} height="400px">
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
        <Flex align={"center"} px="20px" width="50%" style={{ boxSizing: 'border-box' }} justifyContent={'space-between'}>
          <Select placeholder="Select year" onChange={handleYearChange} defaultValue={selectedYear} >
            {years.reverse().map((year, index) => (
              <option key={index} value={year}>{year}</option>
            ))}
          </Select>
          <ReloadIcon
            cursor="pointer"
            onClick={reloadButton}
            color={navbarIcon}
            w="20px"
            h="20px"
          />
          <ToastContainer />
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.1rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading && selectedYear == "All" && Object.keys(data).map((year) => {
              // loop through all the keys under data[year]
              // and calculate the sum of all the values
              // and display the sum in the table
              const totalPerYear = Object.keys(data[year]).reduce((sum, key) => {
                return sum + data[year][key];
              }, 0);
              return (
                <SummaryTableRow
                  label={year}
                  value={totalPerYear}
                />
              );
            })}
            {!isLoading && selectedYear != "All" && data[selectedYear] && (
              // for each of the keys under data[selectedYear]
              // display the key and value in the table
              // key are months represented by numbers, we need to convert them to month names
              // value are the dividends for the month
              Object.keys(data[selectedYear]).map((month) => {
                return (
                  <SummaryTableRow
                    label={monthNames[month - 1]}
                    value={data[selectedYear][month]}
                  />
                );
              })
            )}
            {isLoading && <div>Loading...</div>}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default DividendSummaryTable;