import { Box, Flex, Icon, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import Ticker from "components/Card/Ticker";
import moment from "moment/moment";
import React from "react";
import { FaArrowDown, FaArrowUp, FaArrowsAltH } from "react-icons/fa";
import { toTwoDecimal } from "utils";

function TransactionRow(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const grayColor = useColorModeValue("gray.400", "gray");
  const greenColor = useColorModeValue("green.400", "green");
  const redColor = useColorModeValue("red.400", "red");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { share } = props;

  const getProfit = () => {

    let profit = 0
    if (share.stock_status == "SOLD") {
      profit = share.sold_price - share.purchase_price
      profit = profit * share.quantity
    }
    return profit
  }

  const getIcon = () => {
    const profit = getProfit()
    let icon = profit > 0 ? FaArrowUp : FaArrowDown
    if (profit == 0) {
      icon = FaArrowsAltH
    }
    return icon
  }
  const getColor = () => {
    const profit = getProfit()
    let color = profit > 0 ? greenColor : redColor
    if (profit == 0) {
      color = grayColor
    }
    return color
  }

  return (
    <Flex my="1rem" justifyContent="space-between">
      <Flex alignItems="center">
        <Box
          me="12px"
          borderRadius="50%"
          color={getColor()}
          border="1px solid"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="35px"
          h="35px"
        >
          <Icon as={getIcon()} />
        </Box>
        <Flex direction="column">
          <Ticker
            ticker={share.ticker}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            textColor={textColor}
          />
          <Text
            fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
            color={"gray.400"}
            fontWeight="semibold"
          >
            {moment(share.stock_status == "SOLD" ? share.sold_date : share.purchase_date).format("ll")}
          </Text>
        </Flex>
      </Flex>
      <Box>
        <Text fontSize={{ sm: "md", md: "lg", lg: "md" }} fontWeight="bold">
          {share.stock_status == "SOLD" ? "SOLD" : "BOUGHT"}
        </Text>
      </Box>
      <Box color={getColor()} >
        <Text fontSize={{ sm: "md", md: "lg", lg: "md" }} fontWeight="bold">
          ${share.stock_status == "SOLD" ?
            toTwoDecimal(getProfit()) :
            toTwoDecimal(share.quantity * share.purchase_price)}
        </Text>
        <Text fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
          color={"gray.400"}
          fontWeight="semibold">
          {share.stock_status == "SOLD" ?
            toTwoDecimal(((share.sold_price - share.purchase_price) / share.purchase_price) * 100)
            : 0}%
        </Text>
      </Box>
    </Flex>
  );
}

export default TransactionRow;
