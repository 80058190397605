import moment from "moment/moment";
import { monthNames, shortMonthNames } from "utils";
import DividendTableRow from "./DividendTableRow";

const { Thead, Tr, Tbody, Table, Th } = require("@chakra-ui/react");

const DividendTable = ({ dividends, isLoading }) => {

  const getTotal = () => {
    const output = monthNames.reduce((result, month) => {
      let totalDividend = 0;
      Object.keys(dividends).forEach((tickerDividends) => {
        const dividend = Object.entries(dividends[tickerDividends]).find(([date, amount]) => moment(date, 'MM/DD/YYYY').format('MMMM') === month);
        totalDividend += dividend ? dividend[1] : 0;
      });
      // Convert month to the first day of the month
      const dateKey = moment(month, 'MMMM').date(1).format('MM/DD/YYYY');

      if (totalDividend > 0) {
        result[dateKey] = totalDividend;
      }

      return result;
    }, {});

    return output;
  }

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {
        !isLoading && <Table variant="striped" colorScheme="teal.500" border="1px" borderColor="teal.200" borderRadius="10px" >
          <Thead>
            <Tr>
              <Th>Ticker</Th>
              {shortMonthNames.map((month, index) => (
                <Th key={index}>{month}</Th>
              ))}
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            <DividendTableRow ticker={"Total"} dividends={getTotal()} />
            {Object.keys(dividends).map((ticker) => {
              return (
                <DividendTableRow dividends={dividends[ticker]} ticker={ticker} />
              );
            })}
          </Tbody>
        </Table>
      }
    </>
  )
}

export default DividendTable;