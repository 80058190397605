// Chakra imports
import {
  Box,
  Flex, SimpleGrid
} from "@chakra-ui/react";
// assets
// Custom icons
import { getDividendSummary, getOptionMoM, getOptionSummary, getProfit } from "api";
import { useEffect, useState } from "react";
import DividendSummaryChart from "./components/DividendSummaryChart";
import DividendSummaryTable from "./components/DividendSummaryTable";
import OptionMomChart from "./components/OptionMoMChart";
import OptionMoMTable from "./components/OptionMoMTable";
import OptionSummaryChart from "./components/OptionSummaryChart";
import OptionSummaryTable from "./components/OptionSummaryTable";
import SummaryChart from "./components/SummaryChart";
import SummaryTable from "./components/SummaryTable";

const Summary = () => {
  const [summary, setSummary] = useState([]);
  const [optionSummary, setOptionSummary] = useState([]);
  const [optionMom, setOptionMom] = useState([]);
  const [dividendSummary, setDividendSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProfit().then(setSummary).then(() => setIsLoading(false))
    getOptionSummary().then(setOptionSummary).then(() => setIsLoading(false))
    getDividendSummary().then(setDividendSummary).then(() => setIsLoading(false))
    getOptionMoM().then(setOptionMom).then(() => setIsLoading(false))
  }, [])

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing='24px'>
        <SummaryTable
          title={"Summary"}
          captions={["Year", "Profit"]}
          data={summary}
        />
        <Box gridColumn={{ sm: "span 1", md: "span 2", xl: "span 2" }}>
          <SummaryChart data={summary} />
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing='24px' py="20px">
        <OptionSummaryTable
          title={"Option Summary"}
          captions={["Year", "Profit"]}
          data={optionSummary}
        />
        <Box gridColumn={{ sm: "span 1", md: "span 2", xl: "span 2" }}>
          <OptionSummaryChart data={optionSummary} />
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing='24px' py="20px">
        <DividendSummaryTable
          title={"Dividend Summary"}
          data={dividendSummary}
        />
        <Box gridColumn={{ sm: "span 1", md: "span 2", xl: "span 2" }}>
          <DividendSummaryChart data={dividendSummary} />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing='24px' py="20px">
        <OptionMoMTable
          title={"Option Month Over Month"}
          data={optionMom}
        />
        <Box gridColumn={{ sm: "span 1", md: "span 2", xl: "span 2" }}>
          <OptionMomChart data={optionMom} />
        </Box>
      </SimpleGrid>

    </Flex>
  );
}

export default Summary;
