// Chakra imports
import {
  Flex
} from "@chakra-ui/react";
// assets
// Custom icons
import { getAllOptions } from "api";
import { useEffect, useState } from "react";
import OptionsInfo from "./components/OptionsInfo";

export default function Option() {
  const [allOptions, setAllOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllOptions().then(setAllOptions).then(() => setIsLoading(false))
  }, [])

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <OptionsInfo
        title={"Options Information"}
        captions={["", "Option Name", "Type", "Expiring Date", "Status", ""]}
        data={allOptions}
        isLoading={isLoading}
      />
    </Flex>
  );
}
