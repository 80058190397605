import { reloadShares } from "api";
import { ReloadIcon } from "components/Icons/Icons";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SummaryTableRow from "./SummaryTableRow";

const { Thead, Tr, Tbody, useColorModeValue, Table, Th, Text, Flex, Box } = require("@chakra-ui/react");
const { default: Card } = require("components/Card/Card");
const { default: CardBody } = require("components/Card/CardBody");
const { default: CardHeader } = require("components/Card/CardHeader");

const SummaryTable = ({ title, captions, data, isLoading }) => {
  const textColor = useColorModeValue("gray.700", "white");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  const reloadButton = () => {
    const resolveOnReloadComplete = new Promise((resolve, reject) => reloadShares().then(resp => {
      if (resp.success) {
        resolve()
      } else {
        reject(new Error('Error reloading options'));
      }
    }));
    toast.promise(resolveOnReloadComplete, {
      pending: 'Reloading Shares...',
      success: 'Shares Reloaded',
      error: 'Error Reloading Options'
    }, {
      position: "top-center"
    })
  }

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex align={'center'}>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            {title}
          </Text>
          <Box px="20px" width="50%" style={{ boxSizing: 'border-box' }}>
            <ReloadIcon
              cursor="pointer"
              onClick={reloadButton}
              color={navbarIcon}
              w="20px"
              h="20px"
            />
          </Box>
          <ToastContainer />
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.1rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading && Object.keys(data).map((year) => {
              return (
                <SummaryTableRow
                  label={year}
                  value={data[year]}
                />
              );
            })}
            {isLoading && <div>Loading...</div>}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default SummaryTable;