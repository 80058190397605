// import
import SignIn from "views/Auth/SignIn.js";
import DailyView from "views/Dashboard/DailyView";
import Dashboard from "views/Dashboard/Dashboard";
import Dividends from "views/Dashboard/Dividends";
import Earnings from "views/Dashboard/Earnings";
import Option from "views/Dashboard/Option";
import Summary from "views/Dashboard/Summary";

import {
  CreditIcon,
  DocumentIcon,
  HomeIcon,
  RocketIcon,
  StatsIcon
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/earnings",
    name: "Earnings",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    component: Earnings,
    layout: "/admin",
  },
  {
    path: "/summary",
    name: "Summary",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Summary,
    layout: "/admin",
  },
  {
    path: "/dividends",
    name: "Dividends",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Dividends,
    layout: "/admin",
  },
  {
    path: "/daily",
    name: "Daily View",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    component: DailyView,
    layout: "/admin",
  },
  {
    path: "/options",
    name: "Options",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    component: Option,
    layout: "/admin",
  },
  {
    name: "ACCOUNT PAGES",
    ignore: true,
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
