// Chakra imports
import {
  Flex,
  Grid
} from "@chakra-ui/react";
// assets
// Custom icons
import { getEarnings } from "api";
import { useEffect, useState } from "react";
import EarningsCalendar from "./components/EarningsCalendar";
import EarningsInfo from "./components/EarningsInfo";
export default function Earnings() {
  const [earnings, setEarnings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEarnings().then(setEarnings).then(() => setIsLoading(false))
  }, [])

  return (

    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "3fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        my='26px'
        gap='24px'>
        <EarningsInfo
          title={"Earnings Information"}
          captions={["", "Price", "Earning Date", "News"]}
          data={earnings}
          isLoading={isLoading}
        />

        <EarningsCalendar
          title={"Earnings Calendar"}
          captions={["Date", ""]}
          data={earnings}
          isLoading={isLoading}
        />
      </Grid>

    </Flex>
  );
}
