import {
  Box,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import Ticker from "components/Card/Ticker";
import React from "react";
import {
  FaArrowDown,
  FaArrowUp,
} from "react-icons/fa";
import { toTwoDecimal } from "utils";

const DashboardTableRow = (props) => {
  const { name, ticker, quantity, boughtPrice, tickerInfo } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const greenColor = useColorModeValue("green.400", "green");
  const redColor = useColorModeValue("red.300", "red");

  const getTickerPrice = (tickerInfo) => {

    if (!tickerInfo) {
      return (
        <Text>Loading...</Text>
      )
    }

    const marketPrice = toTwoDecimal(tickerInfo.regularMarketPrice)
    const marketChange = toTwoDecimal(tickerInfo.regularMarketChange)
    const marketChangePercent = toTwoDecimal(tickerInfo.regularMarketChangePercent * 100)
    const color = tickerInfo.regularMarketChange > 0 ? greenColor : redColor
    const direction = tickerInfo.regularMarketChange > 0 ? FaArrowUp : FaArrowDown
    return (
      <Flex minWidth={"100%"}>
        <Box color={color}
          borderRadius="50%"
          border="1px solid"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={"0.5rem"}
          w="30px"
          h="30px">
          <Icon as={direction} />
        </Box>
        <Text fontSize="md" fontWeight="bold" px={"1rem"} >
          ${marketPrice}
        </Text>
        <Text fontSize="md" color={color} fontWeight="bold" px={"1rem"} >
          {marketChange} ({marketChangePercent}%)
        </Text>
      </Flex>
    )
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr maxHeight="10px">
      <Td minWidth={{ sm: "200px" }} px="5px" py="10px">
        <Flex direction="column">
          <Ticker
            withLogo={true}
            ticker={ticker} isOpen={isOpen} onOpen={onOpen} onClose={onClose} textColor={textColor} />
          <Text
            fontSize={{ sm: "xs", md: "sm", lg: "xs" }}
            color="gray.400"
            fontWeight="semibold"
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: "350px" }} py="0.1rem">
        <Flex align="center" py=".1rem" minWidth="100%" flexWrap="nowrap">
          {getTickerPrice(tickerInfo)}
        </Flex>
      </Td>

      <Td minWidth={{ sm: "5px" }} py="0.1rem">
        <Text fontSize="md" color={textColor} fontWeight="bold" align={"right"}>
          {toTwoDecimal(quantity)}
        </Text>
      </Td>

      <Td minWidth={{ sm: "150px" }} py="0.1rem">
        <Flex align="left" py=".1rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth="100%" align={"left"} >
            ${toTwoDecimal(boughtPrice)}
          </Text>
        </Flex>
      </Td>

    </Tr>
  );
}

export default DashboardTableRow;
