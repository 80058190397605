import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        isLoading ? (
          <div>Loading...</div>
        ) :
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to="/auth/signin" />
          )
      }
    />
  );
};

export default ProtectedRoute;