// Chakra imports
import {
  Flex,
  Grid
} from "@chakra-ui/react";
import { getDailyWatchlist } from "api";
import { useEffect, useState } from "react";
import Daily from "./components/Daily";

export default function DailyView() {
  const [dailyWatchlist, setDailyWatchlist] = useState([]);

  useEffect(() => {
    getDailyWatchlist().then(setDailyWatchlist)
  }, [])

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <Grid templateColumns={{ sm: "1fr", md: "1fr 2fr", lg: "2fr 3fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        my='26px'
        gap='24px'>
        <Daily
          title={"Daily View"}
          captions={["", "Name", "Price"]}
          data={dailyWatchlist}
        />
      </Grid>
    </Flex>
  );
}
