// Chakra Imports
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Grid,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { getFundamentals } from "api";
import { Separator } from "components/Separator/Separator";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AddToWatchlistButton from './WatchlistButton'; // Make sure the path is correct


const Fundamentals = (props) => {
  const { secondary, isOpen, onClose, fixed, ticker, ...rest } = props;
  const [fundamentals, setFundamentals] = useState();

  const settingsRef = React.useRef();

  useEffect(() => {
    if (isOpen) {
      getFundamentals(ticker).then(fundamentals => {
        setFundamentals(fundamentals)
      })
    }
  }, [isOpen, ticker])

  const getDividendPercentage = (dividendTTM) => {
    const percentageMatch = dividendTTM.match(/\((\d+\.\d+)%\)/); // Regular expression to match the percentage value
    if (percentageMatch && percentageMatch.length > 1) {
      const percentageValue = percentageMatch[1]; // Extracting the percentage value
      console.log(percentageValue); // Output the extracted percentage value
      return percentageValue + "%"
    } else {
      console.log("Percentage value not found.");
      return "--"
    }
  }

  const getVolume = (volume) => {
    let cleanedString = volume.replace(/,/g, ''); // Replace all commas globally
    let vol = parseFloat(cleanedString)
    if (vol >= 1000000) {
      return (vol / 1000000).toFixed(1) + ' M';
    } else if (vol >= 1000) {
      return (vol / 1000).toFixed(1) + ' K';
    } else {
      return vol.toString();
    }
  }

  return (
    <Drawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      placement={document.documentElement.dir === "rtl" ? "left" : "right"}
      finalFocusRef={settingsRef}
      blockScrollOnMount={false}
      size="sm"
    >
      <DrawerContent>
        <DrawerHeader pt="24px" px="24px">
          <DrawerCloseButton />
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <AddToWatchlistButton ticker={ticker} />
              <Text fontSize="xl" ml="8px">
                ${fundamentals && fundamentals.info && fundamentals.info.Price}
              </Text>
            </Flex>
          </Flex>
          <Text fontSize="md" mb="16px">
            {fundamentals && fundamentals.info && fundamentals.info.Company}
          </Text>
          <Separator />
        </DrawerHeader>
        {fundamentals && (
          <DrawerBody w="470px" ps="24px" pe="40px">
            {/* Create 3 columns with left column containing informataion like dividend, p/e
              and right column containing information like 52W High, 52W low */}

            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
              <Box>
                <Flex direction="row">
                  <Text fontSize="sm" mr={1}>Dividend:</Text>
                  <Text fontSize="md" color="teal.500" fontWeight="bold">{getDividendPercentage(fundamentals.info["Dividend TTM"])}</Text>
                </Flex>
                <Flex direction="row">
                  <Text fontSize="sm" mr={1}>Volume:</Text>
                  <Text fontSize="md" color="teal.500" fontWeight="bold">{getVolume(fundamentals.info["Volume"])}</Text>
                </Flex>
              </Box>
              <Box>
                <Flex direction="row">
                  <Text fontSize="sm" mr={1}>P/E:</Text>
                  <Text fontSize="md" color="teal.500" fontWeight="bold">{fundamentals.info["P/E"]}</Text>
                </Flex>
              </Box>
              <Box>
                <Flex direction="row">
                  <Text fontSize="sm" mr={1}>52W Low:</Text>
                  <Text fontSize="md" color="teal.500" fontWeight="bold">{fundamentals.info["52W Range From"]}</Text>
                </Flex>
                <Flex direction="row">
                  <Text fontSize="sm" mr={1}>52W High:</Text>
                  <Text fontSize="md" color="teal.500" fontWeight="bold">{fundamentals.info["52W Range To"]}</Text>
                </Flex>
              </Box>
            </Grid>

            <Flex flexDirection="column" mt="24px">
              <Separator />
              <Box mt="24px">
                {/* Iterate over fundamentals.news and create a list of news entry */}
                {fundamentals.news && fundamentals.news.map((news) => {
                  return (
                    <Flex
                      direction="column"
                      border="1px solid #E2E8F0"
                      borderRadius="md"
                      p="10px"
                      mb="10px"
                      _hover={{ bg: "gray.100" }}
                      transition="background 0.3s"
                    >
                      <Text fontSize="xs" color="gray.500">
                        {moment(news.Date).format('ll')}
                      </Text>
                      <Link
                        href={news.Link}
                        fontSize="md"
                        fontWeight="bold"
                        color="teal.500"
                        target="_blank"
                        rel="noopener noreferrer"
                        mt="2px"
                        _hover={{ textDecoration: "underline" }}
                      >
                        {news.Title}
                      </Link>
                    </Flex>
                  );
                })}
                {fundamentals.insider && fundamentals.insider.length > 0 ? (
                  <>
                    <Text fontSize="md" fontWeight="600">
                      Insider Trading
                    </Text>
                    {/* Iterate over fundamentals.insider and create a insider trading activities
                with information about the date, Insider Trading (person), transaction and #Shares
                */}

                    <Table variant="simple" size="md">
                      <Thead>
                        <Tr>
                          <Th>Date</Th>
                          <Th>Name</Th>
                          <Th>Type</Th>
                          <Th>Count</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {fundamentals.insider && fundamentals.insider.map((insider, index) => {
                          const isSale = insider.Transaction.toLowerCase() === "sale";
                          const bgColor = isSale ? (index % 2 === 0 ? "red.200" : "red.100") : "";
                          return (
                            <Tr
                              key={insider.Date}
                              bg={bgColor}
                            >
                              <Td fontSize="xs" color={isSale ? "black" : "gray.500"}>
                                {insider.Date}
                              </Td>
                              <Td fontSize="xs" color={isSale ? "black" : "gray.500"}>
                                {insider["Insider Trading"]}
                              </Td>
                              <Td fontSize="md" fontWeight="bold" color={isSale ? "black" : "teal.500"}>
                                {insider.Transaction}
                              </Td>
                              <Td fontSize="xs" color={isSale ? "black" : "gray.500"}>
                                {insider["#Shares"]}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                    <Separator />
                  </>) : (
                  <Text fontSize="md" fontWeight="600">
                    No Insider Trading Data
                  </Text>
                )}
                <Text mt="24px" fontSize="md" fontWeight="600">

                </Text>

              </Box>
            </Flex>
          </DrawerBody>
        )}
      </DrawerContent>
    </Drawer>
  );
}
Fundamentals.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};


export default Fundamentals;