import {
  Flex,
  Link,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import Ticker from "components/Card/Ticker";
import { TdSmall } from "components/Common/TdSmall";
import moment from "moment/moment";
import React from "react";

const TablesTableRow = (props) => {
  const { company, earnings, price, news, ticker, average_volume } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr>
      <TdSmall>
        <Ticker
          ticker={ticker}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          textColor={textColor}
        />
      </TdSmall>

      <TdSmall>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            ${price}
          </Text>
        </Flex>
      </TdSmall>
      <TdSmall>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {earnings}
        </Text>
      </TdSmall>
      <TdSmall>
        <Link href={news[0]["Link"]} target="_blank" rel="noopener noreferrer">
          {moment(news[0]["Date"]).format("lll")} - {news[0]["Title"]}
        </Link>
      </TdSmall>
    </Tr>
  );
}

export default TablesTableRow;
