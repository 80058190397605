// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { getShareSummary } from "api";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import DashboardTableRow from "components/Tables/DashboardTableRow";
import React, { useEffect, useState } from "react";

const Shares = ({ title, captions, stockData }) => {
  const textColor = useColorModeValue("gray.700", "white");

  const [shares, setShares] = useState([]);

  useEffect(() => {
    getShareSummary().then(allShares => {
      setShares(allShares)
    })
  }, [])

  return (
    <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='12px 0px 1px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
        </Flex>
      </CardHeader>
      <Table variant='simple' color={textColor}>
        <Thead>
          <Tr my='.8rem' ps='0px'>
            {captions.map((caption, idx) => {
              return (
                <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null} px={"1rem"}>
                  {caption}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {shares.map((row) => {
            return (
              <DashboardTableRow
                key={row.ticker}
                name={row.ticker_info?.longName}
                ticker={row.ticker}
                quantity={row.quantity}
                boughtPrice={row.purchase_price}
                tickerInfo={stockData && stockData[row.ticker]}
              />
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
};

export default Shares;
