// Chakra imports
import {
  Flex,
  Grid,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
// assets
// Custom icons
import { getProfit, getStockInfo } from "api";
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon
} from "components/Icons/Icons.js";
import React, { useEffect, useState } from "react";
import { toTwoDecimal } from "utils";
import { dashboardTableData } from "variables/general";
import Transactions from "../Billing/components/Transactions";
import MiniStatistics from "./components/MiniStatistics";
import Shares from "./components/Shares";

export default function Dashboard() {
  const iconBoxInside = useColorModeValue("white", "white");
  const [profit, setProfit] = useState({});
  const [stockData, setStockData] = useState({});

  const getStockInfoInLoop = (next) => {
    getStockInfo(next, 19).then(response => {
      setStockData(prevData => ({ ...prevData, ...response.info }))
      if (Object.keys(response.info).length > 0) {
        getStockInfoInLoop(response.next)
      }
    })
  }
  useEffect(() => {
    getProfit().then(setProfit)
    getStockInfoInLoop(1)
  }, [])

  const overallProfit = () => {
    let total = 0
    if (profit) {
      Object.keys(profit).forEach((key) => {
        return total += profit[key]
      })
    }
    return toTwoDecimal(total)
  }
  const currentYearProfit = () => {
    if (profit) {
      return toTwoDecimal(profit[new Date().getFullYear()])
    }
    return 0
  }
  const previousYearProfit = () => {
    if (profit) {
      return toTwoDecimal(profit[new Date().getFullYear() - 1])
    }
    return 0
  }
  const previousToPreviousYearProfit = () => {
    if (profit) {
      return toTwoDecimal(profit[new Date().getFullYear() - 2])
    }
    return 0
  }
  const ytdPercentage = () => {
    if (profit) {
      return toTwoDecimal((currentYearProfit() / previousYearProfit() - 1) * 100)
    }
    return 0
  }
  const previousYearPercentage = () => {
    if (profit) {
      return toTwoDecimal((previousYearProfit() / previousToPreviousYearProfit() - 1) * 100)
    }
    return 0
  }
  const totalOverallPercentage = () => {
    if (profit) {
      return toTwoDecimal((currentYearProfit() / overallProfit()) * 100)
    }
    return 0
  }

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing='24px'>
        <MiniStatistics
          title={"YTD"}
          amount={toTwoDecimal(profit[new Date().getFullYear()])}
          percentage={ytdPercentage()}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Previous Year"}
          amount={toTwoDecimal(profit[new Date().getFullYear() - 1])}
          percentage={previousYearPercentage()}
          icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Total Overall"}
          amount={overallProfit()}
          percentage={totalOverallPercentage()}
          icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>

      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
        templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
        my='26px'
        gap='24px'>
        <Shares
          title={"Shares"}
          amount={30}
          captions={["Name", "Current Price", "Quantity", "Bought Price"]}
          data={dashboardTableData}
          stockData={stockData}
        />
        <Transactions
          title={"Recent Transactions"}
        />
      </Grid>
    </Flex>
  );
}
