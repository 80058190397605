import Card from "components/Card/Card";
import Chart from "react-apexcharts";
import { monthNames } from "utils";


const DividendSummaryChart = ({ data }) => {

  const last5Years = Object.keys(data).sort((a, b) => b - a).slice(0, 5).reverse();

  const barChartData = last5Years.map(year => ({
    name: year,
    data: Array.from({ length: 12 }, (_, i) => data[year][i + 1] || 0)
  }));
  const barChartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      labels: {
        colors: '##fff',
      },
    },
    tooltip: {
      style: {
        backgroundColor: "red",
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          backgroundColor: "red",
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: monthNames,
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "#fff",
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "14px",
        },
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
            },
          },
        },
      },
    ],
  };


  return (
    <Card
      py="1rem"
      width="100%"
      height="100%"
      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
      position="relative"
    >
      <Chart
        options={barChartOptions}
        series={barChartData}
        type="bar"
        width="100%"
        height="100%"
      />
    </Card>
  )
}

export default DividendSummaryChart;