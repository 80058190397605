import SummaryTableRow from "./SummaryTableRow";

const { Thead, Tr, Tbody, useColorModeValue, Table, Th, Text } = require("@chakra-ui/react");
const { default: Card } = require("components/Card/Card");
const { default: CardBody } = require("components/Card/CardBody");
const { default: CardHeader } = require("components/Card/CardHeader");

const OptionSummaryTable = ({ title, captions, data, isLoading }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }} height="400px">
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.1rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading && Object.keys(data).map((year) => {
              return (
                <SummaryTableRow
                  label={year}
                  value={data[year]}
                />
              );
            })}
            {isLoading && <div>Loading...</div>}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default OptionSummaryTable;