import { Badge, Box, Flex, Image, useStyleConfig } from "@chakra-ui/react";
import Fundamentals from "components/Configurator/Fundamentals";

const Ticker = (props) => {
  const { variant, children, ticker, withLogo, ...rest } = props;
  const styles = useStyleConfig("Ticker", { variant });
  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles} {...rest}>
      <Fundamentals
        isOpen={props.isOpen}
        onClose={props.onClose}
        ticker={props.ticker}
      />
      <Flex flexDirection={"row"}>
        {withLogo && (<Image
          mr={"10px"}
          align={"left"}
          borderRadius={"full"}
          boxSize="30px"
          objectFit="cover"
          src={`https://eodhistoricaldata.com/img/logos/US/${ticker}.png`}
          fallbackSrc="https://eodhistoricaldata.com/img/logos/US/MSFT.png"
          alt="Logo"
        />)}
        <Badge
          align={"right"}
          onClick={props.onOpen}
          bg={ticker !== "Online" ? "green.400" : bgStatus}
          color={ticker !== "Online" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
          cursor={"pointer"}
        >
          {props.ticker}
        </Badge>
      </Flex>

    </Box>
  );
}

export default Ticker;
