import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import Ticker from "components/Card/Ticker";
import moment from "moment/moment";

const OptionsTableRow = (props) => {
  const { ticker, type, option_name, quantity, expiring_date, option_status } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr>
      <Td>
        <Ticker
          ticker={ticker}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          textColor={textColor}
        />
        {/* <Badge
          bg={ticker !== "Online" ? "green.400" : bgStatus}
          color={ticker !== "Online" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
          onClick={() => console.log("ticker")}
          cursor={"pointer"}
        >
          {ticker}
        </Badge> */}
      </Td>
      <Td pl="0px" pt="0px" pb="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {/* <Avatar src={logo} w="50px" borderRadius="12px" me="18px" /> */}
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {option_name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {quantity}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {type}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {moment(expiring_date).format("l")}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {option_status}
        </Text>
      </Td>
      {/* <Td>
        <Link href={news[0]["Link"]} target="_blank" rel="noopener noreferrer">
          {moment(news[0]["Date"]).format("lll")} - {news[0]["Title"]}
        </Link>
      </Td> */}
    </Tr>
  );
}

export default OptionsTableRow;
