// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { getEarningsCalendar } from "api";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import EarningsRow from "components/Tables/EarningsRow";
import { useEffect, useState } from "react";

const EarningsCalendar = ({ title, captions }) => {

  const [earningsCalendar, setEarningsCalendar] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    getEarningsCalendar().then(setEarningsCalendar).then(() => setIsLoading(false))
  }, [])

  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.1rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading && earningsCalendar && earningsCalendar.map((row) => {
              return (
                <EarningsRow
                  company={row.ShortName}
                  earnings={row.ExpectedReportDate}
                  price={row.price}
                  ticker={row.Symbol}
                  timeslot={row.Timeslot}
                />
              );
            })}
            {isLoading && <div>Loading...</div>}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default EarningsCalendar;
