// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { ThSmall } from "components/Common/ThSmall";
import TablesTableRow from "components/Tables/TablesTableRow";

const EarningsInfo = ({ title, captions, data, isLoading }) => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.1rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <ThSmall color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </ThSmall>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading && data.map((row) => {
              return (
                <TablesTableRow
                  company={row.company}
                  earnings={row.earnings}
                  price={row.price}
                  ticker={row.ticker}
                  news={row.news}
                />
              );
            })}
            {isLoading && <div>Loading...</div>}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default EarningsInfo;
