import { Td, Tr, useDisclosure } from "@chakra-ui/react";
import Ticker from "components/Card/Ticker";
import moment from "moment";
import { monthNames } from "utils";

const DividendTableRow = ({ ticker, dividends }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Tr>
            <Td p="10px">
                <Ticker ticker={ticker} onOpen={onOpen} isOpen={isOpen} onClose={onClose} />
            </Td>
            {monthNames.map((month, index) => {
                const dividend = Object.entries(dividends).find(([date, amount]) => moment(date, 'MM/DD/YYYY').format('MMMM') === month);
                return <Td p="0px" key={index}>{dividend ? '$' + dividend[1].toFixed(2) : '-'}</Td>
            })}
            <Td p="10px">
                $
                {Object.keys(dividends).reduce((totalDividend, dateEntry) => {
                    return totalDividend + dividends[dateEntry];
                }, 0).toFixed(2)}
            </Td>
        </Tr>
    );
}

export default DividendTableRow;