import { AddIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { addWatchlist, removeWatchlist, getWatchlistByTicker } from 'api';
import React, { useState, useEffect } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const AddToWatchlistButton = ({ ticker }) => {
    const [selectedItems, setSelectedItems] = useState({});
    // Step 1: Fetch the watchlist data on component mount
    useEffect(() => {
        getWatchlistByTicker(ticker).then((watchlist) => {
            const itemsSelection = watchlist.reduce((acc, item) => {
                if (item.ticker === ticker) {
                    acc[item.list_type] = true;
                }
                return acc;
            }, {});
            setSelectedItems(itemsSelection);
        });
    }, [ticker]);


    // Step 2: Update the handleAdd function
    const handleAdd = (type) => {
        // call addToWatchlist or removeFromWatchlist based on the new state
        if (!selectedItems[type]) {
            addToWatchlist(ticker, type);
        } else {
            removeFromWatchlist(ticker, type);
        }
    };

    // Mock function to add ticker to watchlist
    const addToWatchlist = (ticker, type) => {
        const response = addWatchlist(ticker, type).then(response => {
            console.log('Added to watchlist:', response);
            toast.success(`Added ${ticker} to watchlist`, { position: "top-center" });
            setSelectedItems(prevState => ({
                ...prevState,
                [type]: !prevState[type],
            }));
        })
    };

    // Mock function to remove ticker from watchlist
    const removeFromWatchlist = (ticker, type) => {
        const response = removeWatchlist(ticker, type).then(response => {
            console.log(`Removed ${ticker} from watchlist`);
            toast.success(`Removed ${ticker} from watchlist`, { position: "top-center" });
            setSelectedItems(prevState => ({
                ...prevState,
                [type]: !prevState[type],
            }));
        });
    };

    return (
        <>
            <Menu isLazy>
                <MenuButton as={Button} rightIcon={<AddIcon />} size="md">
                    {ticker}
                </MenuButton>
                <MenuList>
                    {/* Step 3: Adjust the rendering logic */}
                    <MenuItem onClick={() => handleAdd('daily')} icon={selectedItems.daily ? <CheckIcon /> : undefined}>Daily</MenuItem>
                    <MenuItem onClick={() => handleAdd('earnings')} icon={selectedItems.earnings ? <CheckIcon /> : undefined}>Earnings</MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};

export default AddToWatchlistButton;