import {
  Flex,
  Icon,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import Ticker from "components/Card/Ticker";
import { TdSmall } from "components/Common/TdSmall";
import moment from "moment";
import React from "react";


import { FaMoon, FaSun } from "react-icons/fa";
import { column } from "stylis";
const EarningsRow = (props) => {
  const { company, earnings, timeslot, news, ticker, average_volume } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const sunriseGradient = `linear-gradient(to right, #FF8C00, rgba(31, 39, 51, 0.3) 40%, #1f2733)`;
  const sunsetGradient = `linear-gradient(to right, #FF5733, rgba(31, 39, 51, 0.5) 100%, #1f2733)`;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getIcon = (timeslot) => {
    if (timeslot == 20)
      return FaSun
    else return FaMoon
  }
  const getGradient = (timeslot) => {
    if (timeslot == 20)
      return sunriseGradient
    else return sunsetGradient
  }

  return (
    // <Tr style={{ background: getGradient(timeslot), padding: "0px", margin: "10px" }}>
    <Tr>
      <TdSmall>
        <Flex direction={column} >
          <Icon alignSelf="center" height="13px" width="13px" as={getIcon(timeslot)} color={"gray.500"} />
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" padding="0px 5px">
            {/* {moment(earnings).format("MM/DD h:mm a")} */}
            {moment(earnings).format("MM/DD")}
          </Text>
        </Flex>
      </TdSmall>
      <TdSmall>
        {company}
      </TdSmall>
      <TdSmall>
        <Ticker
          ticker={ticker}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          textColor={textColor}
        />
      </TdSmall>
    </Tr>
  );
}

export default EarningsRow;
