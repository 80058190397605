import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


// const API_HOSTNAME = "http://localhost:5000/api"
const API_HOSTNAME = "https://portfolio-api.amusale.com/api"

export const getShares = async () => {
  return fetchGet('/shares/')
}

export const getShareSummary = async () => {
  return fetchGet('/shares/summary')
}

export const getProfit = async () => {
  return fetchGet('/shares/profit')
}

export const getOptionSummary = async () => {
  return fetchGet('/option/summary')
}

export const getStock = async (name) => {
  return fetchGet(`/stocks/${name}`)
}

export const getTransactions = async (limit) => {
  return fetchGet(`/transactions/?limit=${limit}`)
}

export const getStockInfo = async (next, limit) => {
  return fetchGet(`/stock/fininfo?limit=${limit}&next=${next}`)
}

export const login = async (user_id, password) => {
  return fetchPost('/login', { user_id, password })
}

export const validateSession = async () => {
  const resp = await fetch(`${API_HOSTNAME}/validate-session`, {
    credentials: 'include',
  })
  return resp;
}

export const getFundamentals = async (ticker) => {
  return fetchGet(`/stock/fundamentals?ticker=${ticker}`)
}

export const getEarnings = async () => {
  return fetchGet('/earnings/info')
}

export const getEarningsCalendar = async () => {
  return fetchGet(`/earnings/calendar`)
}

export const getAllOptions = async () => {
  return fetchGet('/option/all')
}

export const getDividend = async (year) => {
  return fetchGet('/dividend/?year=' + year)
}

export const getDividendSummary = async () => {
  return fetchGet('/dividend/summary')
}
export const getOptionMoM = async () => {
  return fetchGet('/option/monthovermonth')
}

export const reloadOptions = async () => {
  return fetchGet('/data/load/options')
}

export const reloadDividend = async () => {
  return fetchGet('/data/load/dividends')
}

export const reloadShares = async () => {
  return fetchGet('/data/load/shares')
}
export const getDailyWatchlist = async () => {
  return watchlist('daily')
}
export const getEarningsWatchlist = async () => {
  return watchlist('earnings')
}
export const getWatchlistByTicker = async (ticker) => {
  return fetchGet(`/watchlist/${ticker}`)
}

export const watchlist = async (watchlist_type) => {
  return fetchGet(`/watchlist/?watchlist_type=${watchlist_type}`)
}

export const addWatchlist = async (ticker, list_type) => {
  return fetchPost('/watchlist/', { ticker, list_type })
}
export const removeWatchlist = async (ticker, list_type) => {
  return fetchDelete('/watchlist/', { ticker, list_type })
}

const fetchDelete = async (url, body) => {
  return fetchApi(url, 'DELETE', body)
}
const fetchPost = async (url, body) => {
  return fetchApi(url, 'POST', body)
}

const fetchGet = async (url) => {
  return fetchApi(url, 'GET')
}

const fetchApi = async (url, method, body) => {

  const options = {
    credentials: 'include',
    method: method,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  if (body) {
    options.body = JSON.stringify(body)
  }
  try {
    const resp = await fetch(`${API_HOSTNAME}${url}`, options)
    if (!resp.ok) {
      // throw new Error("failed to fetch")
      toast.error("Response not ok", {
        position: "top-center"
      })
    }
    const data = await resp.json()
    return data;
  } catch (error) {
    toast.error("Failed to fetch data", {
      position: "top-center"
    })
  }

}