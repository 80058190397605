import { login, validateSession } from 'api';
import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const authLogin = async (username, password) => {
    const response = await login(username, password);

    if (response.authenticated) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  // Add logic here to check if the user is authenticated when the app loads
  useEffect(() => {
    const checkAuthentication = async () => {
      setIsLoading(true);
      const response = await validateSession();

      if (response.ok) {
        const data = await response.json()
        setIsAuthenticated(data.authenticated);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkAuthentication();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, authLogin, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};