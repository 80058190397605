// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { getTransactions } from "api";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TransactionRow from "components/Tables/TransactionRow";
import React, { useEffect, useState } from "react";

const Transactions = ({
  title,
  date,
}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [boughtShares, setBoughtShares] = useState([]);
  const [soldShares, setSoldShares] = useState([]);

  useEffect(() => {
    getTransactions(10).then(shares => {
      // const result = shares.reduce((r, o) => {
      //   r[o.stock_status == "SOLD" ? 'sold' : 'bought'].push(o);
      //   return r;
      // }, { sold: [], bought: [] });
      // setSoldShares(result['sold'])
      // setBoughtShares(result['bought'])

      setSoldShares(shares)
    })
  }, [])

  return (
    <Card >
      <CardHeader mb='12px'>
        <Flex direction='column' w='100%'>
          <Flex
            direction={{ sm: "column", lg: "row" }}
            justify={{ sm: "center", lg: "space-between" }}
            align={{ sm: "center" }}
            w='100%'
            my={{ md: "12px" }}>
            <Text
              color={textColor}
              fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
              fontWeight='bold'>
              {title}
            </Text>
            {/* <Flex align='center'>
              <Icon
                as={FaRegCalendarAlt}
                color='gray.400'
                fontSize='md'
                me='6px'></Icon>
              <Text color='gray.400' fontSize='sm' fontWeight='semibold'>
                {date}
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction='column' w='100%'>
          {soldShares.map((share) => {
            return (
              <TransactionRow
                share={share}
              />
            );
          })}
          {/* <Text
            color='gray.400'
            fontSize={{ sm: "sm", md: "md" }}
            fontWeight='semibold'
            my='12px'>
            OLDER
          </Text>
          {boughtShares.map((share) => {
            return (
              <TransactionRow
              share={share}
              />
            );
          })} */}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default Transactions;
