import { toTwoDecimal } from "utils";

const { Tr, Td, Text } = require("@chakra-ui/react");

const SummaryTableRow = ({ label, value }) => {
  return (
    <Tr>
      <Td p="0px">
        <Text
          color={"gray.400"}
          fontWeight="semibold">{label}
        </Text>
      </Td>
      <Td p="5px">
        <Text
          color={"gray.400"}
          fontWeight="bold">
          ${toTwoDecimal(value)}
        </Text>
      </Td>
    </Tr>
  );
}

export default SummaryTableRow;