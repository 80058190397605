import {
  Flex,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import Ticker from "components/Card/Ticker";
import { TdSmall } from "components/Common/TdSmall";
import React from "react";

const DailyViewRow = (props) => {
  const { price, ticker, name } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr>
      <TdSmall>
        <Ticker
          ticker={ticker}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          textColor={textColor}
        />
      </TdSmall>
      <TdSmall>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name ? name : "loading..."}
          </Text>
        </Flex>
      </TdSmall>
      <TdSmall>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {price ? `$${price}` : "loading..."}
          </Text>
        </Flex>
      </TdSmall>
    </Tr>
  );
}

export default DailyViewRow;
