// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import { getFundamentals } from "api";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { ThSmall } from "components/Common/ThSmall";
import DailyViewRow from "components/Tables/DailyViewRow";
import { useEffect, useState } from "react";

const Daily = ({ title, captions, data }) => {

  const [fundamentals, setFundamentals] = useState({})

  useEffect(() => {
    // Use Promise.all to fetch all fundamentals concurrently
    const fetchFundamentals = async () => {
      const promises = data.map(list_item => getFundamentals(list_item.ticker));
      const results = await Promise.all(promises);

      // Create a new object to accumulate the new fundamentals
      const newFundamentals = {};
      data.forEach((list_item, index) => {
        newFundamentals[list_item.ticker] = results[index];
      });

      // Update the state with the new fundamentals, preserving previous state
      setFundamentals(prevFundamentals => ({
        ...prevFundamentals,
        ...newFundamentals
      }));
    };

    fetchFundamentals();
  }, [data]); // Dependency array to re-run the effect if `data` changes


  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.1rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <ThSmall color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </ThSmall>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <DailyViewRow
                  name={fundamentals[row.ticker] && fundamentals[row.ticker]['info']['Company']}
                  price={fundamentals[row.ticker] && fundamentals[row.ticker]['info']['Price']}
                  ticker={row.ticker}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Daily;
